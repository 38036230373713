<template>
  <div id="App">
    <Logo></Logo>
    <van-divider>当前绑定的账号</van-divider>
    <van-row type="flex">
      <van-col span="4"></van-col>
      <van-col span="18">
        <van-field
          label=""
          :value="this.userInfo.cdName"
          label-align="left"
          left-icon="manager"
          label-width="3em"
          readonly
        >
        </van-field>
      </van-col>
      <van-col span="2"></van-col>
    </van-row>
    <van-row gutter="20" style="margin-top: 25px">
      <van-col span="2"></van-col>
      <van-col span="20">
        <van-button round block type="danger" @click="onLoginOut()">
          {{ title }}
        </van-button>
      </van-col>
      <van-col span="2"></van-col>
    </van-row>
  </div>
</template>
<style>
.content {
  padding: 16px 16px;
}
</style>

<script>
import { mapState } from "vuex";
import Logo from "../components/Logo.vue";
import { Dialog } from "vant";
export default {
  data() {
    return {
      title: "解除绑定",
      userInfoOut: {
        cdName: "",
        token: "",
        wechatUserCode: ""
      },
      show: false,
      loading: false,
    };
  },
  components: {
    Logo
  },
  computed: {
    ...mapState(["userInfo"])
  },
  methods: {
    onLoginOut() {
      var that = this;
      if (that.userInfo.wechatUserCode == "") {
        that.$store.commit("setUserInfo", that.userInfoOut);
        that.$router.push("/");
      } else {
        that
          .$axios({
            method: "post",
            url: "/api/Main",
            data: {
              Function: "GetWechatLoginOut",
              Params: [that.userInfo.wechatUserCode]
            },
            headers: {
              Authorization: "Bearer " + that.userInfo.token
            }
          })
          .then(response => {
            if (response.data.status == "0") {
              that.$store.commit("setUserInfo", that.userInfoOut);
              that.$router.push("/");
            } else {
              Dialog({ message: response.data.msg });
            }
          })
          .catch(error => {
            Dialog({ message: error.message });
          });
      }
    },
  },
};
</script>
